/* AddGateway.css */
.modal-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1050;
  }
  
  .modal-overlay.show {
    display: flex;
  }
  
  .modal-content {
    background-color: white;
    border-radius: 4px;
    width: 80%;
    max-width: 500px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 10px;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .close {
    cursor: pointer;
  }